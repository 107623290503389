import axios from "@/axios";
const base = "/api/tournament/";

class TournamentSeasonService {
	async getSeasons() {
		const { data } = await axios.get(`${base}seasons`);
		return data;
	}
	async createSeason({ name, start_date, end_date }) {
		try {
			const data = await axios.post(`${base}seasons/insert-season`, {
				name,
				start_date,
				end_date,
			});
			return data;
		} catch (error) {
			error.response.data;
		}
	}
	async editSeason({ end_date, season_id }) {
		try {
			const data = await axios.put(`${base}seasons/update`, {
				end_date,
				season_id,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editSeasonName({ name, season_id }) {
		const data = await axios.put(`${base}seasons/update-name/${season_id}`, {
			name,
		});
		return data;
	}
}
export default new TournamentSeasonService();
